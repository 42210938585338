import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import queryString from "query-string"
import LayoutDark from "./shared/layout/layout-dark"
import "../../static/assets/scss/pages/page.scss"
import SignUpFormComponent from "./shared/components/sign-up-form.component"
const routes = require("../types/routes")

export default class GetStarted extends React.Component {
  constructor(props) {
    super(props)
    this.routes = routes
    this.email = ""
  }

  componentWillMount() {
    const values = queryString.parse(this.props.location.search)
    if (values.email) {
      this.email = values.email
      console.log(this.email)
    }
  }

  render() {
    return (
      <LayoutDark>
        <Helmet>
          <title>Contingent: Success</title>
          <meta name="robots" content="noindex, nofollow, noarchive" />
        </Helmet>
        <div className={"section-container"}>
          <div className={"section-info basic"}>
            <div className={"container contact-us"}>
              <div className={"row"}>
                <div className={"column"}>
                  <div className={"content"}>
                    <p className={"tag"}>Request a demo</p>
                    <h2 className={"title"}>Create your free account</h2>
                    <p className={"description"}>
                      Create your account now and start banishing manual
                      processes in favour of proactive, ongoing risk monitoring.
                      <br /> <br />
                      One of our representatives will reach out to you to help
                      onboard your company on Contingent.
                    </p>
                    <p className={"description bold"}>
                      Trusted by organisations of all shapes and sizes
                    </p>
                    <div className={"logos collapsed"}>
                      <div className={"group"}>
                        <img
                          src={"/assets/images/logos/ukgovblack.svg"}
                          className={"logo"}
                        />
                        <img
                          src={"/assets/images/logos/monzo-black.svg"}
                          className={"logo"}
                        />
                      </div>
                      <div className={"group"}>
                        <img
                          src={"/assets/images/logos/healthcare.svg"}
                          className={"logo"}
                        />
                        <img
                          src={"/assets/images/logos/vyne.svg"}
                          className={"logo"}
                        />
                      </div>
                    </div>
                  </div>
                  <form className={"form dark-form"}>
                    <h5 className={"header"}>Submission completed</h5>
                    <div className={"body"}>
                      <p className={"text"}>
                        We have successfully processed your application, please
                        check your email{" "}
                        <span className={"bold"}> {this.email} </span>with
                        information the next steps
                      </p>
                    </div>
                    <Link to={"/"} type={"button"} className={"back-to-home"}>
                      Back to home
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"cta-section"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"column"}>
                <div className={"content"}>
                  <p className={"tag"}>Try Contingent Now</p>
                  <h2 className={"title"}>
                    Move towards proactive third-party risk management and
                    monitoring today.
                  </h2>
                  <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                </div>
                <div className={"image"}>
                  <object data={"/assets/images/graphics/cta.svg"}></object>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDark>
    )
  }
}
